import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import styled from '@emotion/styled';
import { FormattedCurrency } from '@genteras/ui-lib';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Menu } from 'antd';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Router from 'next/router';
import type { FC } from 'react';
import { useSWRConfig } from 'swr';
import { urls } from 'constant/urls';
import UserAPI from '../../lib/api/user';
import useUser from '../../lib/hooks/useUser';
import useWindowDimensions from '../../lib/hooks/useWindowDimensions';
import { commonConfig } from '../../lib/i18n/configs';
import { AccountStatus } from './Modal';

const UserMenu = styled(Menu)({
  marginTop: '10px',
  borderRadius: '15px 15px',
  borderWidth: '1px',
  borderColor: 'green',
});

export const UserMenuDropdown: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { user } = useUser();
  const { mutate } = useSWRConfig();
  const { outerWidth } = useWindowDimensions();

  const logout = async () => {
    await UserAPI.logout();
    document.cookie =
      'connect.sid=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    await mutate('/players/me', null);
    Router.push(urls.home).then(() => location.reload());
  };

  const Btn = styled(Button)({
    paddingTop: '0px',
    height: '30px',
    borderRadius: '15px 15px',
    borderColor: 'green',
    borderWidth: '1px',
    display: 'flex',
    lineHeight: '1.9',
  });
  const breakpoints = [910];
  let showMenuDetail = false;
  if (outerWidth) {
    showMenuDetail = outerWidth < 910;
  }

  const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') logout();
  };

  const MenuItems = [
    ...(showMenuDetail
      ? [
          {
            key: 'username',
            disabled: true,
            label: user.username,
          },
          {
            key: 'wallet',
            disabled: true,
            label: (
              <FormattedCurrency
                value={user.playerWallet.balance}
                theme="initial"
              />
            ),
          },
          {
            key: 0,
            type: 'divider',
          },
        ]
      : []),
    {
      key: 1,
      label: <Link href={urls.betRecord}>{t('common:header.betRecord')}</Link>,
    },
    {
      key: 2,
      label: (
        <Link href={urls.memberReport}>{t('common:header.memberReport')}</Link>
      ),
    },
    {
      key: 3,
      label: <Link href={urls.changePassword}>{t('common:Setting')}</Link>,
    },
    {
      key: 'logout',
      label: t('common:header.logout'),
    },
  ];

  const menu = <UserMenu items={MenuItems} onClick={handleMenuClick} />;

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <span>
        <Btn
          css={{
            width: 200,
            paddingBottom: 0,
            borderColor:
              user.overallStatus == AccountStatus.FROZEN
                ? 'red'
                : 'var(--primaryColor)',
            [mq[0]]: {
              width: '30px',
              justifyContent: 'center',
              padding: '0px',
            },
          }}
        >
          <UserOutlined
            css={{
              margin: 'auto 0',
              fontSize: '18px',
              color:
                user.overallStatus == AccountStatus.FROZEN
                  ? 'red'
                  : 'var(--primaryColor)',
            }}
          />
          <div
            css={{
              display: 'flex',
              color: 'black',
              marginLeft: '5px',
              letterSpacing: '1px',
              [mq[0]]: { display: 'none' },
            }}
          >
            {user.username}
          </div>
          <MenuOutlined
            css={{
              margin: 'auto 0 auto auto',
              [mq[0]]: { display: 'none !important' },
            }}
          />
        </Btn>
      </span>
    </Dropdown>
  );
};
