import axios from 'axios';
import Router from 'next/router';
import type { SWRConfiguration } from 'swr';
import { urls } from '../../constant/urls';
import { axiosPlayerBase } from './axiosHelpers';

export default async function (path: string) {
  try {
    const { data } = await axiosPlayerBase.get(path);
    return data;
  } catch (e: unknown) {
    if (
      axios.isAxiosError(e) &&
      (e?.response?.status === 401 || e?.response?.status === 498) &&
      Router.pathname !== urls.disclaimer &&
      Router.pathname !== urls.home &&
      Router.pathname !== urls.login &&
      Router.pathname !== urls.casa98
    ) {
      Router.push(urls.home);
    } else {
      throw e;
    }
  }
}

export const ReportSWRConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: 0,
  shouldRetryOnError: false,
  dedupingInterval: 1000,
  errorRetryInterval: 0,
};
