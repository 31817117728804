import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { commonConfig } from '../../lib/i18n/configs';

const AlertDiv = styled.div({
  borderWidth: 1,
  borderColor: 'red',
  padding: 5,
  height: 60,
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
});

const AlertIcon = styled(ExclamationCircleOutlined)({
  marginRight: 5,
  fontSize: 20,
  color: 'red',
});

const AlertText = styled.span({
  lineHeight: 'normal',
  overflow: 'hidden',
  height: 60,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
});

const FrozenAccountAlert: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <AlertDiv>
      <AlertIcon />
      <AlertText>
        <span>{t('common:FrozenMsgAlert1')}</span>
        <span>{t('common:FrozenMsgAlert2')}</span>
      </AlertText>
    </AlertDiv>
  );
};

export default FrozenAccountAlert;
