import styled from '@emotion/styled';
import { Layout } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { urls } from 'constant/urls';
import type { SiteConfig } from '../../config';
import config, { Sites } from '../../config';
import { commonConfig } from '../../lib/i18n/configs';

const { Footer } = Layout;

const LightFooter = styled(Footer)({
  background: 'transparent',
  padding: '20px',
  textAlign: 'center',
  flex: 'auto',
  maxHeight: '200px',
});

export const MainFooter: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });
  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);
  return (
    <LightFooter>
      <>
        {siteConfig.systemIcon ? (
          <div>
            {t('common:poweredBy')}&nbsp;
            <Image
              src={siteConfig.systemIcon}
              width={25}
              height={25}
              alt="G-square.png"
            />
          </div>
        ) : null}
      </>

      <div style={{ maxWidth: 700, margin: 'auto' }}>
        {t('common:header.disclaimer')}
        <Link href={urls.disclaimer}>{t('common:header.ClickHere')}</Link>
      </div>
      <div>
        {t('common:copyright', {
          year: new Date().getFullYear(),
          sitename: siteConfig.siteName,
        })}
      </div>
    </LightFooter>
  );
};
