import styled from '@emotion/styled';
import { Button } from 'antd';

export const StyledButtonThick = styled(Button)`
  alignitems: 'center';
  display: block !important;
  border-radius: 50px !important;
  background: linear-gradient(to left, #f9a01b 0%, #e25026 100%) !important;
  border: none;
  height: 40px !important;
  color: white !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-width: 80px;
  align-items: center;
  justify-content: center;
`;
