import { LeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { getMainSiteUrl } from '@genteras/ui-lib/base';
import { Modal, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { urls } from 'constant/urls';
import type { SiteConfig } from '../../config';
import config, { Sites } from '../../config';
import useUser from '../../lib/hooks/useUser';
import FrozenAccountAlert from '../common/FrozenAccountAlert';
import FrozenAlert from '../common/FrozenAlert';
import { LanguageDropdown } from '../common/LanguageDropdown';
import { AccountStatus, popupModalCss } from '../common/Modal';
import { UserAmount } from '../common/UserAmount';
import { UserMenuDropdown } from '../common/UserMenuDropdown';
import { NotificationCenter } from '../notification-center/NotificationCenter';

const LightHeader = styled(Header)({
  background: 'transparent',
  padding: '0 20px',
  height: 60,
  [cssMedia.md]: {
    height: 130,
  },
});

const HeaderContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  padding: 0,
  [cssMedia.md]: {
    padding: '40px 0 0',
  },
});

const HeaderLeft = styled.div({
  height: '64px',
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  width: 55,
  [cssMedia.sm]: {
    width: 220,
  },
  [cssMedia.md]: {
    width: 280,
  },
});
const HeaderMiddle = styled.div({
  margin: 5,
});

const HeaderRight = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '64px',
  background: 'transparent',
});

const HeaderRightSpace = styled(Space)({
  [cssMedia.xs]: {
    gap: '3px !important',
  },
});

const BackMainLink = styled.a({
  padding: '0 10px 0 0',
  color: 'var(--primaryColor)',
  transform: 'scaleY(1.5)',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  height: 30,
});

let isFirstLoad = false;

export const MainHeader: FC = () => {
  const { user, isLoading } = useUser();
  const router = useRouter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOnce, setShowOnce] = useState(false);

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });

  useEffect(() => {
    if (isLoading) return;
    if (
      user?.username &&
      !user.passwordLastReset &&
      !router.pathname.includes('reset-password')
    ) {
      router.push(urls.resetPassword);
    }
  }, [user, isLoading, router]);

  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);

  if (
    !isModalVisible &&
    user.overallStatus == AccountStatus.FROZEN &&
    !showOnce &&
    !isFirstLoad
  ) {
    setIsModalVisible(true);
    setShowOnce(true);
    setTimeout(() => {
      isFirstLoad = true;
    }, 100);
  }

  if (showOnce && user.overallStatus != AccountStatus.FROZEN) {
    setShowOnce(false);
  }

  const handleClickLogo = () => {
    router.push(urls.play);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const AieiaLogoImage = styled.img({
    cursor: 'pointer',
    maxHeight: 50,
    width: '234px',
    height: '52px',
    [cssMedia.xs]: {
      content: `url(${siteConfig.favicon})`,
      width: 31,
      height: 31,
    },
  });

  const WinsplaysLogoImage = styled.img({
    cursor: 'pointer',
    maxHeight: 50,
    width: '200px',
    height: '31px',
    [cssMedia.xs]: {
      content: `url(${siteConfig.favicon})`,
      width: 31,
      height: 31,
    },
  });

  const SA36LogoImage = styled.img({
    cursor: 'pointer',
    width: '250px',
    [cssMedia.xs]: {
      width: '100px',
    },
  });

  const BlackAntsLogoImage = styled.img({
    cursor: 'pointer',
    width: '250px',
    [cssMedia.xs]: {
      width: '100px',
    },
  });

  function LogoImage({ siteName }: { siteName: string }) {
    const props = {
      src: siteConfig.logoHeader,
      alt: 'logo',
      onClick: handleClickLogo,
    };
    switch (siteName) {
      case 'AIEIA':
        return <AieiaLogoImage {...props} />;
      case 'Winsplays':
        return <WinsplaysLogoImage {...props} />;
      case 'BlackAnts':
        return <BlackAntsLogoImage {...props} />;
      default:
        return <SA36LogoImage {...props} />;
    }
  }

  return (
    <>
      <LightHeader>
        <HeaderContainer>
          <HeaderLeft>
            {user ? null : (
              <BackMainLink href={getMainSiteUrl(router.locale)}>
                <LeftOutlined />
              </BackMainLink>
            )}
            {siteConfig.logoHeader &&
              router.pathname !== '/' &&
              router.pathname !== '/login' && (
                <LogoImage siteName={siteConfig.siteName} />
              )}
          </HeaderLeft>
          <HeaderMiddle>
            {user.overallStatus == AccountStatus.FROZEN ? (
              <FrozenAccountAlert />
            ) : null}
          </HeaderMiddle>
          <HeaderRight>
            <HeaderRightSpace>
              {isLoading || !user.username ? null : (
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 15,
                    alignItems: 'center',
                  }}
                >
                  <NotificationCenter />
                  <UserMenuDropdown />
                  <UserAmount />
                </div>
              )}
              <LanguageDropdown />
            </HeaderRightSpace>
          </HeaderRight>
        </HeaderContainer>
      </LightHeader>
      <Modal
        css={popupModalCss}
        centered
        width={800}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <FrozenAlert />
      </Modal>
    </>
  );
};
