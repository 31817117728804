import { BellOutlined } from '@ant-design/icons';
import type { JSX } from '@emotion/react/jsx-runtime';
import type { CSSInterpolation } from '@emotion/serialize';
import styled from '@emotion/styled';
import { notify } from '@genteras/ui-lib/base';
import { Badge, Dropdown, List } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useState } from 'react';
import { mutate } from 'swr';
import UserAPI from '@/lib/api/user';
import useAnnouncements from '@/lib/hooks/useAnnouncements';
import useUser from '@/lib/hooks/useUser';
import { commonConfig } from '../../lib/i18n/configs';
import { StyledButtonThick } from '../base/StyledButtonThick';
import { AnnouncementList } from './AnnouncementList';

export const StyledListItem = styled(List.Item)`
  white-space: pre-line;
  &:hover {
    background-color: #f7f7f7;
  }
  padding-right: 24px;
  padding-left: 24px;
  cursor: pointer;
  transition: all 0.3s;
`;

const StyledBtnWrapper = styled.div({
  textAlign: 'center',
  marginTop: '12px',
  marginBottom: '12px',
  height: '32px',
  lineHeight: '32px',
});

const ListStyle: CSSInterpolation = {
  ['::-webkit-scrollbar']: {
    display: 'none',
  },
  maxHeight: '400px',
  overflow: 'auto',
};

export const listCss: CSSInterpolation = {
  overflow: 'auto',
};

export const dateCss: CSSInterpolation = {
  marginTop: '4px',
  fontSize: '12px',
  lineHeight: 1.5715,
};

export const NotificationCenter: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { user } = useUser();
  const [announcementPageIndex, setAnnouncementPageIndex] = useState(1);
  const { announcements } = useAnnouncements(announcementPageIndex);
  const [bellVisible, setBellVisible] = useState(false);
  const AnnouncementLists: JSX.Element[] = [];

  const maxAnnouncementPage = announcements.meta.totalPages;
  const unreadAnnouncementsCount = user.unreadAnnouncementsCount;

  for (let i = 1; i <= announcementPageIndex; i++) {
    AnnouncementLists.push(<AnnouncementList pageIndex={i} key={i} />);
  }

  const handleBellChange = (visible: boolean) => {
    setBellVisible(visible);
    setAnnouncementPageIndex(1);
    if (!visible) {
      mutate('/players/me');
    }
  };

  const handleBellClick = async () => {
    try {
      await UserAPI.updateLastNotifiedAt();
    } catch (e: unknown) {
      notify.error({ e, t });
    }
  };

  const onLoadAnnouncementMore = () => {
    setAnnouncementPageIndex(announcementPageIndex + 1);
  };

  const loadAnnouncementMore =
    announcementPageIndex < maxAnnouncementPage ? (
      <StyledBtnWrapper>
        <StyledButtonThick onClick={onLoadAnnouncementMore} type={'primary'}>
          {t('LoadMore')}
        </StyledButtonThick>
      </StyledBtnWrapper>
    ) : null;

  const DropdownList = () => {
    return (
      <div css={ListStyle}>
        {AnnouncementLists}
        {loadAnnouncementMore}
      </div>
    );
  };
  return (
    <Dropdown
      placement="bottom"
      overlay={DropdownList}
      overlayStyle={{
        position: 'relative',
        width: '336px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxSizing: 'border-box',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
      trigger={['click']}
      visible={bellVisible}
      onVisibleChange={handleBellChange}
    >
      <Badge
        count={unreadAnnouncementsCount || 0}
        offset={[4, -2]}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
        }}
      >
        <BellOutlined
          onClick={handleBellClick}
          style={{
            fontSize: '1.5em',
            color: 'var(--primaryColor)',
          }}
        />
      </Badge>
    </Dropdown>
  );
};
