import AieiaFavicon from '../public/images/aieia/favicon.png';
import AieiaLogoHeader from '../public/images/aieia/logo-black.png';
import AieiaLogoCompact from '../public/images/aieia/logo-s.png';
import BlackAntsLogo from '../public/images/blackAnts/logo.svg';
import WinsplaysSystemIcon from '../public/images/G-square.png';
import SA36LogoHeader from '../public/images/sa36/logo.png';
import WinsplaysFavicon from '../public/images/winsplays/favicon.png';
import WinsplaysLogoCompact from '../public/images/winsplays/logo-s.png';
import WinsplaysLogoHeader from '../public/images/winsplays/logo.png';

export enum Sites {
  BLACKANTS = 'www.blackants.com',
  AIEIA = 'www.aieia.com',
  DEFAULT = 'play.winsplays.com',
  LOCAL = 'localhost',
  SA36 = 'www.sa36.com',
  WINSPLAYS = 'www.winsplays.com',
}

export type SiteConfig = {
  siteName: string;
  logoHeader: string;
  logoCompact: string;
  favicon: string;
  systemIcon: string;
};

const sitesConfig: Map<Sites, SiteConfig> = new Map([
  [
    Sites.AIEIA,
    {
      siteName: 'AIEIA',
      logoHeader: AieiaLogoHeader.src,
      logoCompact: AieiaLogoCompact.src,
      favicon: AieiaFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.DEFAULT,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: WinsplaysSystemIcon.src,
    },
  ],
  [
    Sites.LOCAL,
    {
      siteName: 'AIEIA',
      logoHeader: AieiaLogoHeader.src,
      logoCompact: AieiaLogoCompact.src,
      favicon: AieiaFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.SA36,
    {
      siteName: 'SA36',
      logoHeader: SA36LogoHeader.src,
      logoCompact: SA36LogoHeader.src,
      favicon: SA36LogoHeader.src,
      systemIcon: '',
    },
  ],
  [
    Sites.BLACKANTS,
    {
      siteName: 'BlackAnts',
      logoHeader: BlackAntsLogo.src,
      logoCompact: BlackAntsLogo.src,
      favicon: BlackAntsLogo.src,
      systemIcon: '',
    },
  ],
  [
    Sites.WINSPLAYS,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: '',
    },
  ],
]);

export default {
  sitesConfig,
};
