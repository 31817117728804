export const urls = {
  home: '/',
  betRecord: '/bet-record',
  memberReport: '/member-report',
  changePassword: '/changePassword',
  resetPassword: '/reset-password',
  disclaimer: '/disclaimer',
  loadGame: '/loadgame',
  login: '/login',
  casa98: '/casa98',
  play: '/play',
};
