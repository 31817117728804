import { datadogRum } from '@datadog/browser-rum';
import type { EmotionCache } from '@emotion/react';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Schema from 'async-validator';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { urls } from 'constant/urls';
import { AppProviders } from '../app-providers';
import { MainFooter } from '../components/layout/Footer';
import { MainHeader } from '../components/layout/Header';
import type { SiteConfig } from '../config';
import config, { Sites } from '../config';

// eslint-disable-next-line @typescript-eslint/no-empty-function
Schema.warning = () => {};

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'genteras-player',
  env: process.env.NEXT_PUBLIC_ENV || '',
  allowedTracingUrls: [
    `${process.env.NEXT_PUBLIC_BASE_URL}/api`,
    'aieia.com/api',
    'sa36.com/api',
  ],

  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

/**
 * Import global styles, global css or polyfills here
 * i.e.: import '@/assets/theme/style.scss'
 */
import '../styles/global.css';

/**
 * Local fonts
 * @link https://fontsource.org/docs/guides/nextjs
 */
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
// @link https://fontsource.org/docs/variable-fonts
import '@fontsource/inter/variable.css';

// antd
import 'antd/dist/antd.css';
import '../styles/vars.css';

// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error;
  emotionCache?: EmotionCache;
};

const MainLayout = styled(Layout)({
  background: 'transparent !important',
  minHeight: '100vh',
});

const Container = styled(Layout)`
  margin: auto;
  background: transparent;
`;

const PageContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
`;

const MainContent = styled(Content)({
  background: 'transparent',
  // minHeight: 550,
  padding: '0 20px',
  flex: 'auto',
});

const ignoreHeaderFooterUrls = [urls.loadGame, urls.casa98];

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: AppProps) => {
  const { Component, pageProps, emotionCache, err } = appProps;
  const { pathname } = useRouter();

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });
  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);
  const siteName = siteConfig.siteName || '';
  return (
    <AppProviders emotionCache={emotionCache}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <DefaultSeo
        title={siteName || ''}
        description={siteName + ' offers a brand-new gaming experience'}
        openGraph={{
          type: 'website',
          locale: 'en-US',
          url: process.env.NEXT_PUBLIC_BASE_URL,
          site_name: siteName,
        }}
      />

      {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}

      {ignoreHeaderFooterUrls.some((x) => x === pathname) ? (
        <Component {...pageProps} err={err} />
      ) : (
        <PageContainer
          className={
            siteName === 'SA36' && (pathname === '/' || pathname === '/login')
              ? 'sa36'
              : 'pageContainer'
          }
        >
          <Container className="landingCont">
            <MainLayout>
              <MainHeader />
              <MainContent>
                <Component {...pageProps} err={err} />
              </MainContent>
              <MainFooter />
            </MainLayout>
          </Container>
        </PageContainer>
      )}
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js"
        async={true}
        defer={true}
      />
    </AppProviders>
  );
};

/**
 * Generally don't enable getInitialProp if you don't need to,
 * all your pages will be served server-side (no static optimizations).
 */
/*
MyApp.getInitialProps = async appContext => {
   // calls page's `getInitialProps` and fills `appProps.pageProps`
   const appProps = await App.getInitialProps(appContext)
   return { ...appProps }
}
*/

export default appWithTranslation(MyApp);
